import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Md5} from 'ts-md5/dist/md5';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import {ParallelHasher} from 'ts-md5/dist/parallel_hasher';
 


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    user: new FormControl(null, Validators.required),
    psw: new FormControl(null, Validators.required)
  });

  hide = true;
  md5 = new Md5();

  url = 'https://apimbk.diegobrunero.it/login';
  // url = 'http://localhost:3333/login';
  constructor(private http: HttpClient, private router: Router) { }
   hasher = new ParallelHasher('/path/to/ts-md5/dist/md5_worker.js');
 
  ngOnInit() {
   


    
 
  }

  logIn(){

    if(this.loginForm.valid){
      let ps = this.loginForm.value.psw;

      let user = {
        username: this.loginForm.value.user.toString(),
        psw: this.md5.start().appendStr(ps.toString()).end(),
      }
    //   this.hasher.hash(ps).then(function(result) {
    //     console.log('md5 of fileBlob is', result);
    //  });
  
     //console.log(user);
     // console.log(user.psw);
      return this.http.post(this.url, user).subscribe(
        data => {
          let res: any = data;
        // console.log(res);
          sessionStorage.setItem('token', res.accessToken);
          console.log(
            'call done'
          );
        // console.log(res.accessToken);
          // if(res.accessToken!=''||res.accessToken!=null){
            this.router.navigateByUrl("/");
          // }

         // console.log(sessionStorage.getItem('token'));
         // this.operatoriFilter=this.operatori;
        //  console.log(data);
    }, err =>{
      Swal.fire({
        title: 'ATTENZIONE!',
        text: 'Controlla le tue credenziali e riprova',
        icon: 'warning',
        confirmButtonText: 'RIPROVA'
      });
    })}else{
      //TODO ERRORI
    }
    
  }

}
