import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { promise } from 'protractor';
import Swal from 'sweetalert2';


export interface DialogData {
  nome: string;
  cognome: string;
  localita: string;
  email: number;
  telefono: number;
  marcaVendita: string;
  modelloVendita: string;
  annoMotoVendita: number;
  kmMotoVendita: number;
  prezzoScontato: number;
  prezzoListino: number;
  spese: string;
  prezzoSpese: number;
  accessorioUno: string;
  accessorioDue: string;
  accessorioTre: string;
  prezzoAccessorioUno: number;
  prezzoAccessorioDue: number;
  prezzoAccessorioTre: number;
  prezzoFinale: number;
  totaleListino: number;
  marcaPermuta: string;
  modelloPermuta: string;
  annoMotoPermuta: number;
  kmMotoPermuta: number;
  targa: string;
  note: string;
  valutazione: number;
  finalizzato: number;
  nuovoUsato: number;
  operatore: string;
  data: string;
  id: number;
}
@Component({
  selector: 'app-form-modifica-preventivo',
  templateUrl: './form-modifica-preventivo.component.html',
  styleUrls: ['./form-modifica-preventivo.component.scss']
})
export class FormModificaPreventivoComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public http: HttpClient,
    public dialogRef: MatDialogRef<FormModificaPreventivoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  chiudi(): void {
    this.dialogRef.close();
  }
  stampare: boolean;

  chiudiEdit(): any {

    this.dialogRef.close(true);
  }
  totaleVendita: number;
  disabled: boolean;
  ngOnInit() {
    this.stampare = false;

    this.disabled = true;
    this.modForm.disable();
    this.operatoriFilter = this.operatori;
    this.operatoriFilter = this.operatoriFilter
      .filter(obj => {
        return obj.nome.trim().toLowerCase() == (this.data.operatore.trim().toLowerCase())
      });
    console.log(this.operatoriFilter);
    //this.totaleVendita=this.data.totaleListino;
    this.totaleVendita = Number(this.data.prezzoListino) + Number(this.data.prezzoSpese);
    if (this.data.prezzoAccessorioUno == null && this.data.prezzoAccessorioDue != null && this.data.prezzoAccessorioTre != null) {
      this.totaleVendita = Number(this.data.prezzoListino) + Number(this.data.prezzoSpese) + Number(this.data.prezzoAccessorioDue) + Number(this.data.prezzoAccessorioTre);
    }
    if (this.data.prezzoAccessorioUno != null && this.data.prezzoAccessorioDue == null && this.data.prezzoAccessorioTre != null) {
      this.totaleVendita = Number(this.data.prezzoListino) + Number(this.data.prezzoSpese) + Number(this.data.prezzoAccessorioUno) + Number(this.data.prezzoAccessorioTre);
    }
    if (this.data.prezzoAccessorioUno != null && this.data.prezzoAccessorioDue != null && this.data.prezzoAccessorioTre == null) {
      this.totaleVendita = Number(this.data.prezzoListino) + Number(this.data.prezzoSpese) + Number(this.data.prezzoAccessorioDue) + Number(this.data.prezzoAccessorioUno);
    }
    if (this.data.prezzoAccessorioUno == null && this.data.prezzoAccessorioDue == null && this.data.prezzoAccessorioTre != null) {
      this.totaleVendita = Number(this.data.prezzoListino) + Number(this.data.prezzoSpese) + Number(this.data.prezzoAccessorioTre);
    }
    if (this.data.prezzoAccessorioUno != null && this.data.prezzoAccessorioDue == null && this.data.prezzoAccessorioTre == null) {
      this.totaleVendita = Number(this.data.prezzoListino) + Number(this.data.prezzoSpese) + Number(this.data.prezzoAccessorioUno);
    }
    if (this.data.prezzoAccessorioUno == null && this.data.prezzoAccessorioDue != null && this.data.prezzoAccessorioTre == null) {
      this.totaleVendita = Number(this.data.prezzoListino) + Number(this.data.prezzoSpese) + Number(this.data.prezzoAccessorioDue);
    }
    if (this.data.prezzoAccessorioUno != null && this.data.prezzoAccessorioDue != null && this.data.prezzoAccessorioTre != null) {
      this.totaleVendita = Number(this.data.prezzoListino) + Number(this.data.prezzoSpese) + Number(this.data.prezzoAccessorioTre) + Number(this.data.prezzoAccessorioDue) + Number(this.data.prezzoAccessorioUno);
    }

    console.log(this.data);
    console.log("LIS");
    console.log(this.totaleVendita);

    this.modForm.controls.mprezzoListinoControl.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[0] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );



    this.modForm.controls.mprezzoSpeseControl.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[1] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.modForm.controls.mprezzoAcc1Control.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[2] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.modForm.controls.mvalutazioneControl.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        // console.log(val);
        // this.prezzi[2]= val;
        this.sottrazione(val);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );


    this.modForm.controls.mprezzoAcc2Control.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[3] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.modForm.controls.mprezzoAcc3Control.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[4] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.modForm.controls.totaleScontatom.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {

        this.sottrazione(0);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );
  }

  enableForm() {
    this.modForm.enable();
    this.disabled = false;
  }


  disableForm() {
    this.modForm.disable();
    this.disabled = true;
  }


  pfinal = this.data.prezzoFinale;
  prezzi = [this.data.prezzoListino, this.data.prezzoSpese, this.data.prezzoAccessorioUno, this.data.prezzoAccessorioDue, this.data.prezzoAccessorioTre]
  somma(prezzi: number[]) {
    this.totaleVendita = Number(this.prezzi[0] != null ? this.prezzi[0] : 0) + Number(this.prezzi[1] != null ? this.prezzi[1] : 0) + Number(this.prezzi[2] != null ? this.prezzi[2] : 0) + Number(this.prezzi[3] != null ? this.prezzi[3] : 0) + Number(this.prezzi[4] != null ? this.prezzi[4] : 0);
    console.log('somma');
    console.log(this.prezzi[0] + this.prezzi[1]);
  }

  sottrazione(val: number) {
    this.pfinal = Number(this.modForm.value.totaleScontatom != null ? this.modForm.value.totaleScontatom : 0) - Number(this.modForm.value.mvalutazioneControl != null ? this.modForm.value.mvalutazioneControl : 0);
  }




  modForm = new FormGroup({
    mnomeControl: new FormControl(this.data.nome, Validators.required),
    mcognomeControl: new FormControl(this.data.cognome, Validators.required),
    mlocalitaControl: new FormControl(this.data.localita, Validators.required),
    memailControl: new FormControl(this.data.email),
    mntelefonoControl: new FormControl(this.data.telefono),
    mmarcaVenditaControl: new FormControl(this.data.marcaVendita, Validators.required),
    mmodelloVenditaControl: new FormControl(this.data.modelloVendita, Validators.required),
    mannoMotoVenditaControl: new FormControl(this.data.annoMotoVendita),
    mkmMotoVenditaControl: new FormControl(this.data.kmMotoVendita),
    totaleScontatom: new FormControl(this.data.prezzoScontato, Validators.required),
    mprezzoListinoControl: new FormControl(this.data.prezzoListino, Validators.required),
    mspeseControl: new FormControl(this.data.spese),
    mprezzoSpeseControl: new FormControl(this.data.prezzoSpese),
    maccessorio1Control: new FormControl(this.data.accessorioUno),
    maccessorio2Control: new FormControl(this.data.accessorioDue),
    maccessorio3Control: new FormControl(this.data.accessorioTre),
    mprezzoAcc1Control: new FormControl(this.data.prezzoAccessorioUno),
    mprezzoAcc2Control: new FormControl(this.data.prezzoAccessorioDue),
    mprezzoAcc3Control: new FormControl(this.data.prezzoAccessorioTre),
    // totaleVenditaControl: new FormControl(''),
    mmarcaPermutaControl: new FormControl(this.data.marcaPermuta),
    mmodelloPermutaControl: new FormControl(this.data.modelloPermuta),
    mannoMotoPermutaControl: new FormControl(this.data.annoMotoPermuta),
    mkmMotoPermutaControl: new FormControl(this.data.kmMotoPermuta),
    mvalutazioneControl: new FormControl(this.data.valutazione),
    mprezzoFinaleControl: new FormControl(0),
    mtargaPermutaControl: new FormControl(this.data.targa),
    mfinalizzatoControl: new FormControl(this.data.finalizzato, Validators.required),
    mnuovoUsato: new FormControl(this.data.nuovoUsato, Validators.required),
    moperatoreControl: new FormControl(this.data.operatore, Validators.required),
    mnoteControl: new FormControl(this.data.note),
    stampaCtl: new FormControl(false),

  });
  // url = 'http://localhost:3333/modifica';
  url = 'https://apimbk.diegobrunero.it/modifica';

  operatori = [
    { nome: "Luca", valore: "Luca", cognome: "Brunetto", tel: "0124 35770", email: "l.brunetto@motorbikecenter.it" },
    { nome: "Alessandro C.", valore: "Alessandro C.", cognome: "Casazza", tel: "0124 35770", email: "a.casazza@motorbikecenter.it" },
    { nome: "Dario", valore: "Dario", cognome: "Monticone", tel: "011 19902499", email: "d.monticone@motorbikecenter.it" },
    { nome: "Daniele", valore: "Daniele", cognome: "Argiolas", tel: "011 19902499", email: "d.argiolas@motorbikecenter.it" },
    { nome: "Alessandro G.", valore: "Alessandro G.", cognome: "Grandi", tel: "011 19902499", email: "a.grandi@motorbikecenter.it" },
    { nome: "Mattia", valore: "Mattia", cognome: "Gerardo", tel: "0124 35770", email: "g.mattia@motorbikecenter.it" }

  ];
  operatoriFilter = [
    { nome: "Luca", valore: "Luca", cognome: "Brunetto", tel: "0124 35770", email: "l.brunetto@motorbikecenter.it" },
    { nome: "Alessandro C.", valore: "Alessandro C.", cognome: "Casazza", tel: "0124 35770", email: "a.casazza@motorbikecenter.it" },
    { nome: "Dario", valore: "Dario", cognome: "Monticone", tel: "011 19902499", email: "d.monticone@motorbikecenter.it" },
    { nome: "Daniele", valore: "Daniele", cognome: "Argiolas", tel: "011 19902499", email: "d.argiolas@motorbikecenter.it" },
    { nome: "Alessandro G.", valore: "Alessandro G.", cognome: "Grandi", tel: "011 19902499", email: "a.grandi@motorbikecenter.it" },
    { nome: "Mattia", valore: "Mattia", cognome: "Gerardo", tel: "0124 35770", email: "g.mattia@motorbikecenter.it" }
  ];
  nuovousato = [
    { nome: "Nuovo", valore: 1 },
    { nome: "Usato", valore: 0 }
  ]

  finalizzazione = [
    { nome: "Non finalizzato", valore: 0 },
    { nome: "Finalizzato", valore: 1 }
  ];

  opstmp: { nome: string, valore: string, cognome: string, tel: string, email: string };
  stampa() {
    // const printContent = document.getElementById("print");
    // const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    // WindowPrt.document.write(printContent.innerHTML);
    // WindowPrt.document.close();
    // WindowPrt.focus();
    // WindowPrt.print();
    // //WindowPrt.close();


    print();
  }

  touchForm(form) {
    for (let i in form.controls) {
      form.controls[i].markAsTouched();
    }
  }

  modificaPreventivo() {
    console.log(this.modForm);
    let preventivo = {
      nome: this.modForm.value.mnomeControl,
      cognome: this.modForm.value.mcognomeControl,
      localita: this.modForm.value.mlocalitaControl,
      email: this.modForm.value.memailControl != '' ? this.modForm.value.memailControl : null,
      telefono: this.modForm.value.mntelefonoControl,
      marcaVendita: this.modForm.value.mmarcaVenditaControl,
      modelloVendita: this.modForm.value.mmodelloVenditaControl,
      annoMotoVendita: this.modForm.value.mannoMotoVenditaControl,
      kmMotoVendita: this.modForm.value.mkmMotoVenditaControl,
      prezzoListino: this.modForm.value.mprezzoListinoControl,
      prezzoScontato: this.modForm.value.totaleScontatom,
      spese: this.modForm.value.mspeseControl != '' ? this.modForm.value.mspeseControl : null,
      prezzoSpese: this.modForm.value.mspeseControl != '' ? this.modForm.value.mprezzoSpeseControl : null,
      accessorioUno: this.modForm.value.maccessorio1Control != '' ? this.modForm.value.maccessorio1Control : null,
      accessorioDue: this.modForm.value.maccessorio2Control != '' ? this.modForm.value.maccessorio2Control : null,
      accessorioTre: this.modForm.value.maccessorio3Control != '' ? this.modForm.value.maccessorio3Control : null,
      prezzoAccessorioUno: this.modForm.value.mprezzoAcc1Control,
      prezzoAccessorioDue: this.modForm.value.mprezzoAcc2Control,
      prezzoAccessorioTre: this.modForm.value.mprezzoAcc3Control,
      // totaleVendita: this.nuovoPrevForm.value.totaleVenditaControl,
      // totaleVendita: this.totVendita,
      marcaPermuta: this.modForm.value.mmarcaPermutaControl,
      modelloPermuta: this.modForm.value.mmodelloPermutaControl,
      annoMotoPermuta: this.modForm.value.mannoMotoPermutaControl,
      kmMotoPermuta: this.modForm.value.mkmMotoPermutaControl,
      valutazione: this.modForm.value.mvalutazioneControl,
      note: this.modForm.value.mnoteControl != '' ? this.modForm.value.mnoteControl : null,
      nuovoUsato: this.modForm.value.mnuovoUsato,
      operatore: this.modForm.value.moperatoreControl,
      prezzoFinale: this.pfinal,
      // prezzoFinale: this.nuovoPrevForm.value.prezzoFinaleControl,
      targa: this.modForm.value.mtargaPermutaControl,
      finalizzato: this.modForm.value.mfinalizzatoControl,
      totaleListino: this.totaleVendita,
      id: this.data.id
    }
    this.stampare = this.modForm.value.stampaCtl;

    console.log(preventivo);
    if (this.modForm.valid) {
      return this.http.put(this.url, preventivo, {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }
      }).subscribe(
        data => {
          let res: any = data;
          console.log(
            'call done'
          );
          if (this.stampare) {
            print();
          }
          this.chiudiEdit();
        },
        err => {
          Swal.fire({
            title: 'OPS!',
            text: 'Qualcosa è andato storto! Riprova',
            icon: 'warning',
            confirmButtonText: 'RIPROVA'
          });
          this.router.navigateByUrl("/visualizza-preventivi");
          console.log(err);
        }, () => {
          console.log('done');
          //this.openConfirm();

        }
      );
    } else {
      this.touchForm(this.modForm);
      document.getElementById("totScont").style.color = "red";
      document.getElementById("totScont").style.border = "1px solid red";
      Swal.fire({
        title: 'ATTENZIONE!',
        text: 'Controlla i campi del form',
        icon: 'error',
        confirmButtonText: 'CHIUDI'
      })
      return console.log("novalid");
    }
  }



}
