import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime }
  from 'rxjs/operators';
import { SimplePlaceholderMapper } from '@angular/compiler/src/i18n/serializers/serializer';
import { Router } from '@angular/router';
import { NOMEM } from 'dns';
import Swal from 'sweetalert2';
export interface op {
  nome: string,
  cognome: string,
  email: string,
  tel: string
}

@Component({
  selector: 'app-form-nuovo-preventivo',
  templateUrl: './form-nuovo-preventivo.component.html',
  styleUrls: ['./form-nuovo-preventivo.component.scss']
})



@Injectable()
export class FormNuovoPreventivoComponent implements OnInit {

  nuovousato = [
    { nome: "Nuovo", valore: 1 },
    { nome: "Usato", valore: 0 }
  ]

  finalizzazione = [
    { nome: "Non finalizzato", valore: 0 },
    { nome: "Finalizzato", valore: 1 }
  ];

  findefault = "Non finalizzato";
  totVendita: number;
  stampare: boolean;

  opscelto: op = {
    nome: "luca",
    cognome: "brunetto",
    email: "",
    tel: ""
  }

  nuovoPrevForm = new FormGroup({
    nomeControl: new FormControl('', Validators.required),
    cognomeControl: new FormControl('', Validators.required),
    localitaControl: new FormControl('', Validators.required),
    emailControl: new FormControl(null),
    ntelefonoControl: new FormControl(null),
    marcaVenditaControl: new FormControl('', Validators.required),
    modelloVenditaControl: new FormControl('', Validators.required),
    annoMotoVenditaControl: new FormControl(null),
    kmMotoVenditaControl: new FormControl(null),
    // prezzoScontatoControl: new FormControl('', Validators.required),
    prezzoListinoControl: new FormControl(null, Validators.required),
    speseControl: new FormControl(null),
    prezzoSpeseControl: new FormControl(null),
    accessorio1Control: new FormControl(null),
    accessorio2Control: new FormControl(null),
    accessorio3Control: new FormControl(null),
    prezzoAcc1Control: new FormControl(null),
    prezzoAcc2Control: new FormControl(null),
    prezzoAcc3Control: new FormControl(null),
    totaleScontato: new FormControl(null, Validators.required),
    // totaleVenditaControl: new FormControl(''),
    marcaPermutaControl: new FormControl(null),
    modelloPermutaControl: new FormControl(null),
    annoMotoPermutaControl: new FormControl(null),
    kmMotoPermutaControl: new FormControl(null),
    valutazioneControl: new FormControl(0),
    // prezzoFinaleControl: new FormControl(''),
    targaPermutaControl: new FormControl(null),
    finalizzatoControl: new FormControl(0, Validators.required),
    nuovoUsato: new FormControl(null, Validators.required),
    operatoreControl: new FormControl(null, Validators.required),
    noteControl: new FormControl(null),
    stampaCtl: new FormControl(true)
    //  totaleListinoControl: new FormControl('')
  });
  date;
  pfinal: number;
  pscontato: number;
  constructor(public http: HttpClient, private router: Router) { }
  prezzi = [0, 0, 0, 0, 0];
  ngOnInit() {
    this.stampare = true;
    this.nuovoPrevForm.controls.finalizzatoControl.patchValue(0);

    this.nuovoPrevForm.controls.prezzoListinoControl.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[0] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.date = new Date();

    this.nuovoPrevForm.controls.prezzoSpeseControl.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[1] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.nuovoPrevForm.controls.prezzoAcc1Control.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[2] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.nuovoPrevForm.controls.valutazioneControl.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        // console.log(val);
        // this.prezzi[2]= val;
        this.sottrazione(val);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );


    this.nuovoPrevForm.controls.prezzoAcc2Control.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[3] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.nuovoPrevForm.controls.prezzoAcc3Control.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {
        console.log(val);
        this.prezzi[4] = val;
        this.somma(this.prezzi);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );

    this.nuovoPrevForm.controls.totaleScontato.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(
      val => {

        this.sottrazione(0);
        // this.totVendita = val; // qui devi calcolare la somma dei formcontrol
      }
    );
    this.loadInsert = false;
  }



  somma(prezzi: number[]) {
    console.log(this.prezzi);
    this.totVendita = Number(this.prezzi[0] != null ? this.prezzi[0] : 0) + Number(this.prezzi[1] != null ? this.prezzi[1] : 0) + Number(this.prezzi[2] != null ? this.prezzi[2] : 0) + Number(this.prezzi[3] != null ? this.prezzi[3] : 0) + Number(this.prezzi[4] != null ? this.prezzi[4] : 0);
    console.log('somma');
    console.log(this.totVendita);
    //console.log(this.prezzi[0]+this.prezzi[1]);
  }

  sottrazione(val: number) {
    this.pfinal = Number(this.nuovoPrevForm.value.totaleScontato != null ? this.nuovoPrevForm.value.totaleScontato : 0) - Number(this.nuovoPrevForm.value.valutazioneControl != null ? this.nuovoPrevForm.value.valutazioneControl : 0);
  }

  url = 'https://apimbk.diegobrunero.it/add';
  // url = 'http://localhost:3333/add';

  somethingChanged() {
    this.totVendita = this.nuovoPrevForm.value.prezzoScontatoControl;
  }

  data = {
    nome: this.nuovoPrevForm.value.nomeControl,
    cognome: this.nuovoPrevForm.value.cognomeControl,
    localita: this.nuovoPrevForm.value.localitaControl,
    email: this.nuovoPrevForm.value.emailControl != '' ? this.nuovoPrevForm.value.emailControl : null,
    telefono: this.nuovoPrevForm.value.ntelefonoControl,
    marcaVendita: this.nuovoPrevForm.value.marcaVenditaControl,
    modelloVendita: this.nuovoPrevForm.value.modelloVenditaControl,
    annoMotoVendita: this.nuovoPrevForm.value.annoMotoVenditaControl,
    kmMotoVendita: this.nuovoPrevForm.value.kmMotoVenditaControl,
    prezzoListino: this.nuovoPrevForm.value.prezzoListinoControl,
    prezzoScontato: this.nuovoPrevForm.value.totaleScontato,
    spese: this.nuovoPrevForm.value.speseControl != '' ? this.nuovoPrevForm.value.speseControl : null,
    prezzoSpese: this.nuovoPrevForm.value.speseControl != '' ? this.nuovoPrevForm.value.prezzoSpeseControl : null,
    accessorioUno: this.nuovoPrevForm.value.accessorio1Control != '' ? this.nuovoPrevForm.value.accessorio1Control : null,
    accessorioDue: this.nuovoPrevForm.value.accessorio2Control != '' ? this.nuovoPrevForm.value.accessorio2Control : null,
    accessorioTre: this.nuovoPrevForm.value.accessorio3Control != '' ? this.nuovoPrevForm.value.accessorio3Control : null,
    prezzoAccessorioUno: this.nuovoPrevForm.value.prezzoAcc1Control,
    prezzoAccessorioDue: this.nuovoPrevForm.value.prezzoAcc2Control,
    prezzoAccessorioTre: this.nuovoPrevForm.value.prezzoAcc3Control,
    // totaleVendita: this.nuovoPrevForm.value.totaleVenditaControl,
    // totaleVendita: this.totVendita,
    marcaPermuta: this.nuovoPrevForm.value.marcaPermutaControl,
    modelloPermuta: this.nuovoPrevForm.value.modelloPermutaControl,
    annoMotoPermuta: this.nuovoPrevForm.value.annoMotoPermutaControl,
    kmMotoPermuta: this.nuovoPrevForm.value.kmMotoPermutaControl,
    valutazione: this.nuovoPrevForm.value.valutazioneControl,
    note: this.nuovoPrevForm.value.noteControl != '' ? this.nuovoPrevForm.value.noteControl : null,
    nuovoUsato: this.nuovoPrevForm.value.nuovoUsato,
    operatore: this.nuovoPrevForm.value.operatoreControl,
    prezzoFinale: this.pfinal,
    // prezzoFinale: this.nuovoPrevForm.value.prezzoFinaleControl,
    targa: this.nuovoPrevForm.value.targaPermutaControl,
    finalizzato: this.nuovoPrevForm.value.finalizzatoControl,
    totaleListino: this.totVendita
  }

  salvaestampa() {
    this.insertPreventivo();
  }

  stampa() {

    print();
  }

  touchForm(form) {
    for (let i in form.controls) {
      form.controls[i].markAsTouched();
    }
  }

  operatori = [
    { nome: "Luca", valore: "Luca", cognome: "Brunetto", tel: "0124 35770", email: "l.brunetto@motorbikecenter.it" },
    { nome: "Alessandro C.", valore: "Alessandro C.", cognome: "Casazza", tel: "0124 35770", email: "a.casazza@motorbikecenter.it" },
    { nome: "Dario", valore: "Dario", cognome: "Monticone", tel: "011 19902499", email: "d.monticone@motorbikecenter.it" },
    { nome: "Daniele", valore: "Daniele", cognome: "Argiolas", tel: "011 19902499", email: "d.argiolas@motorbikecenter.it" },
    { nome: "Alessandro G.", valore: "Alessandro G.", cognome: "Grandi", tel: "011 19902499", email: "a.grandi@motorbikecenter.it" },
    { nome: "Mattia", valore: "Mattia", cognome: "Gerardo", tel: "0124 35770", email: "g.mattia@motorbikecenter.it" }
  ];
  operatoriFilter = [
    { nome: "Luca", valore: "Luca", cognome: "Brunetto", tel: "0124 35770", email: "l.brunetto@motorbikecenter.it" },
    { nome: "Alessandro C.", valore: "Alessandro C.", cognome: "Casazza", tel: "0124 35770", email: "a.casazza@motorbikecenter.it" },
    { nome: "Dario", valore: "Dario", cognome: "Monticone", tel: "011 19902499", email: "d.monticone@motorbikecenter.it" },
    { nome: "Daniele", valore: "Daniele", cognome: "Argiolas", tel: "011 19902499", email: "d.argiolas@motorbikecenter.it" },
    { nome: "Alessandro G.", valore: "Alessandro G.", cognome: "Grandi", tel: "011 19902499", email: "a.grandi@motorbikecenter.it" },
    { nome: "Mattia", valore: "Mattia", cognome: "Gerardo", tel: "0124 35770", email: "g.mattia@motorbikecenter.it" }
  ];

  loadInsert: boolean;

  insertPreventivo() {

    console.log(this.nuovoPrevForm);
    let preventivo = {
      nome: this.nuovoPrevForm.value.nomeControl,
      cognome: this.nuovoPrevForm.value.cognomeControl,
      localita: this.nuovoPrevForm.value.localitaControl,
      email: this.nuovoPrevForm.value.emailControl != '' ? this.nuovoPrevForm.value.emailControl : null,
      telefono: this.nuovoPrevForm.value.ntelefonoControl,
      marcaVendita: this.nuovoPrevForm.value.marcaVenditaControl,
      modelloVendita: this.nuovoPrevForm.value.modelloVenditaControl,
      annoMotoVendita: this.nuovoPrevForm.value.annoMotoVenditaControl,
      kmMotoVendita: this.nuovoPrevForm.value.kmMotoVenditaControl,
      prezzoListino: this.nuovoPrevForm.value.prezzoListinoControl,
      prezzoScontato: this.nuovoPrevForm.value.totaleScontato,
      spese: this.nuovoPrevForm.value.speseControl != '' ? this.nuovoPrevForm.value.speseControl : null,
      prezzoSpese: this.nuovoPrevForm.value.speseControl != '' ? this.nuovoPrevForm.value.prezzoSpeseControl : null,
      accessorioUno: this.nuovoPrevForm.value.accessorio1Control != '' ? this.nuovoPrevForm.value.accessorio1Control : null,
      accessorioDue: this.nuovoPrevForm.value.accessorio2Control != '' ? this.nuovoPrevForm.value.accessorio2Control : null,
      accessorioTre: this.nuovoPrevForm.value.accessorio3Control != '' ? this.nuovoPrevForm.value.accessorio3Control : null,
      prezzoAccessorioUno: this.nuovoPrevForm.value.prezzoAcc1Control,
      prezzoAccessorioDue: this.nuovoPrevForm.value.prezzoAcc2Control,
      prezzoAccessorioTre: this.nuovoPrevForm.value.prezzoAcc3Control,
      // totaleVendita: this.nuovoPrevForm.value.totaleVenditaControl,
      // totaleVendita: this.totVendita,
      marcaPermuta: this.nuovoPrevForm.value.marcaPermutaControl,
      modelloPermuta: this.nuovoPrevForm.value.modelloPermutaControl,
      annoMotoPermuta: this.nuovoPrevForm.value.annoMotoPermutaControl,
      kmMotoPermuta: this.nuovoPrevForm.value.kmMotoPermutaControl,
      valutazione: this.nuovoPrevForm.value.valutazioneControl,
      note: this.nuovoPrevForm.value.noteControl != '' ? this.nuovoPrevForm.value.noteControl : null,
      nuovoUsato: this.nuovoPrevForm.value.nuovoUsato,
      operatore: this.nuovoPrevForm.value.operatoreControl,
      prezzoFinale: this.pfinal,
      // prezzoFinale: this.nuovoPrevForm.value.prezzoFinaleControl,
      targa: this.nuovoPrevForm.value.targaPermutaControl,
      finalizzato: this.nuovoPrevForm.value.finalizzatoControl,
      totaleListino: this.totVendita
    }
    this.stampare = this.nuovoPrevForm.value.stampaCtl;
    this.data = preventivo;
    console.log(preventivo);
    if (this.nuovoPrevForm.valid) {
      this.loadInsert = true;

      return this.http.post(this.url, preventivo, {
        headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem('token')
        }        
      }).subscribe(
        data => {
          let res: any = data;
          console.log(
            'call done'
          );
          // this.operatoriFilter=this.operatori;
          console.log(data);
          this.operatoriFilter = this.operatori;
          this.operatoriFilter = this.operatoriFilter
            .filter(obj => {
              return obj.nome.trim().toLowerCase() == (preventivo.operatore.trim().toLowerCase())
            });
          if (preventivo.operatore == "Luca") {
            this.opscelto = { nome: "Luca", cognome: "Brunetto", tel: "0124 35770", email: "l.brunetto@motorbikecenter.it" };

          }


          if (preventivo.operatore == "Alessandro") {
            this.opscelto = { nome: "Alessandro", cognome: "Casazza", tel: "0124 35770", email: "a.casazza@motorbikecenter.it" };

          }

          if (preventivo.operatore == "Dario") {
            this.opscelto = { nome: "Dario", cognome: "Monticone", tel: "011 19902499", email: "d.monticone@motorbikecenter.it" };

          }

          if (preventivo.operatore == "Davide") {
            this.opscelto = { nome: "Davide", cognome: "Camelin", tel: "011 19902499", email: "d.camelin@motorbikecenter.it" };

          }
          if (preventivo.operatore == "Alessandro G.") {
            this.opscelto = { nome: "Alessandro", cognome: "Grandi", tel: "011 19902499", email: "a.grandi@motorbikecenter.it" }

          }
          if (preventivo.operatore == "Mattia") {
            this.opscelto = { nome: "Mattia", cognome: "Gerardo", tel: "0124 35770", email: "g.mattia@motorbikecenter.it" }

          }
          document.getElementById("nomeR").innerHTML = this.operatoriFilter[0].nome + " " + this.operatoriFilter[0].cognome;
          document.getElementById("mailR").innerHTML = this.operatoriFilter[0].email.toLowerCase();
          document.getElementById("telR").innerHTML = this.operatoriFilter[0].tel;


          console.log(this.operatoriFilter);
          if (this.stampare) {
            print();
          }
          this.loadInsert = false;
        },
        err => {

          Swal.fire({
            title: 'OPS!',
            text: 'Qualcosa è andato storto! Riprova',
            icon: 'warning',
            confirmButtonText: 'RIPROVA'
          });
          this.router.navigateByUrl("/nuovo-preventivo");
          this.loadInsert = false;
          console.log(err);
        }, () => {
          console.log('done');
          //this.openConfirm();
          this.router.navigateByUrl("/visualizza-preventivi");
        }
      );
    } else {
      this.touchForm(this.nuovoPrevForm);
      document.getElementById("totScont").style.color = "red";
      document.getElementById("totScont").style.border = "1px solid red";

      Swal.fire({
        title: 'ATTENZIONE!',
        text: 'Controlla i campi del form',
        icon: 'error',
        confirmButtonText: 'CHIUDI'
      })
      return console.log("novalid");
    }
  }

}
